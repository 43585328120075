<template>
    <div class="page-content">
        <page-breadcrumb v-if="item" title="Quiz Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
            <b-card class="mt-1" v-if="item">
                <form-generator :model="item" :schema="item_form_schema">
                    <template #slot_answers="{ slotData }">
                        <div v-for="(answer, index) in slotData" :key="'content'+index" class="answer">
                            <p>Answer {{ index + 1 }}</p>
                            <b-input v-model="answer.content"></b-input>
                            <p class="mb-0">Is correct?</p>
                            <b-checkbox v-model="answer.is_correct"></b-checkbox>
                        </div>
                    </template>
                </form-generator>
                <div class="text-center mt-2">
                    <b-button
                        @click="addAnswer"
                        variant="flat-success"
                        class="btn-primary"
                        size="sm"
                    >
                        + Add
                    </b-button>
                </div>
            </b-card>
        </section>
        <action-buttons update-button back-button delete-button @update="update" :is-updating="isUpdating"
            @delete="deleteItem" :is-deleting="isDeleting" />
    </div>

</template>
<script>
const item_form_schema = [
    {
        cols: 12,
        fields: [
            { label: 'Question', field: 'question', validate: { required: true } },
            { label: 'Answers', field: 'answers', input_type: 'slot' },
        ],
    },
];
const answer_schema = [
    {
        cols: 8,
        fields: [
            { label: 'Content', field: 'content' },
        ],
    },
    {
        cols: 4,
        fields: [
            { label: 'Is correct', field: 'is_correct' },
        ],
    },
]
import service from '../service'
export default {
    data() {
        return {
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false,
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Breaking Quiz',
                    to: { name: 'breaking-quiz-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created() {
        this.getDetail()
    },
    methods: {
        async getDetail() {
            this.item = await service.get({ id: this.itemId });
        },
        async update() {
            this.isUpdating = true;
            let data = { ...this.item };
            data.name = JSON.stringify(data.name)
            await service.update({
                data: JSON.stringify(data),
            });
            this.getDetail();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "breaking-quiz-list" });
        },
        addAnswer(){
            this.item.answers.push({
                content: null,
                is_correct: false
            })
        }
    }
}
</script>
<style lang="scss" scoped >
.answer {
    border: 1px dashed black;
    padding: 5px;
    margin-bottom: 5px;
}
</style>